import { FileService } from '../Utils/FileService'
import { uuid } from '../Utils/index'
import { PopupContainer, ISize } from '../Utils/PopupContainer'
import { DrawType, IPosition } from './Anotate'

export interface IImageConfig {
  SessionId: string;
  BoardContainer: HTMLDivElement;
  FileUploadService: FileService;
}
export interface IImageBox {
  UserId: string;
  ImageData: string;
  Pos: IPosition;
  Shape: ISize;
  Type: DrawType;
}
export class ImageBox {
  private popup: PopupContainer;
  private boardContainer: HTMLDivElement;
  private width: number = 150;
  private height: number = 150;
  private image: HTMLImageElement = document.createElement('img');
  private content: HTMLDivElement;
  private _fileService: FileService;
  private _selectedFile: File|null = null;
  private _sessionId: string;
  private _imageRatio: number = -1;
  constructor (config: IImageConfig) {
      // this.width = config.Width;
      // this.height = config.Height;
      // this.fontSize = config.FontSize;
      this._sessionId = config.SessionId
      this.boardContainer = config.BoardContainer
      this.content = document.createElement('div')
      this._fileService = config.FileUploadService
      this.popup = new PopupContainer({
          BoardContainer: this.boardContainer,
          Content: this.image,
          Width: this.width,
          Height: this.height,
          EnableResize: true
      })
      this.popup.OnSubmit = this.OnSubmit
      this.popup.OnCancel = this.OnCancel
      this.popup.OnResize = this.OnResize
      this.LoadImage()
  }

  OnImageSubmit (imagebox: IImageBox) { }
  OnSubmit = () => {
      // let imageData = this.GetImageData(this.image);

      if (this._selectedFile) {
          this.UploadFile(this._selectedFile)
      }
  };

  OnResize = (x: number, y: number, width: number, height: number) => {
      // calculate image ratio, <0 = landscape, >0 = potraite
      const divRaito = width / height

      if (this._imageRatio > 0) {
          if (divRaito > 0 && divRaito < this._imageRatio) {
              this.image.style.width = '100%'
              this.image.style.height = ''
          }
          if (divRaito > this._imageRatio) {
              this.image.style.width = ''
              this.image.style.height = '100%'
          }
      }
  }

  async UploadFile (file: File) {
      const req = await this._fileService.uploadFile(file, this._sessionId, uuid())
      if (req.response?.isSuccess) {
          const pos = this.popup.GetPosition()
          const size = { Width: this.image.width, Height: this.image.height }

          this.OnImageSubmit({
              UserId: '',
              ImageData: req.response.result.streamURL,
              Pos: pos,
              Shape: size,
              Type: DrawType.Image
          })
          this.Remove()
      }
  }

  HandleFileUploadResponse (response:boolean) {
      if (response) {
          console.log(response)
      }
  }

  OnCancel = () => {
      this.popup.Remove()
  };

  OnClear = () => {
      this.popup.Remove()
  };

  DrawTextInCanvas = (text: string, position: IPosition, size: ISize) => { };

  Remove () {
      // dispose popup container
      this.popup.Remove()

      // clear selected file memory
      this._selectedFile = null
  }

  GetImageData (image: HTMLImageElement): string {
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      canvas.width = image.width
      canvas.height = image.height
      context?.drawImage(image, 0, 0, image.width, image.height)
      return canvas.toDataURL()
  }

  LoadImage () {
      const fileInput = document.createElement('input')
      fileInput.type = 'file'
      fileInput.onchange = (e) => {
          const reader = new FileReader()
          reader.onloadend = () => {
              // this.image.style.width = 'inherit'
              // this.image.style.height = 'inherit'
              // this.image.style.maxHeight = 'inherit'
              // this.image.style.maxWidth = 'inherit'
              this.image.src = <string>reader.result
              this.image.onload = () => {
                  // setTimeout(() => {
                  //   if (this.image.width >= this.image.height) {
                  //     this.image.style.width = 'inherit'
                  //     this.image.style.height = 'auto'
                  //   } else {
                  //     this.image.style.width = ''
                  //     this.image.style.height = 'auto'
                  //   }
                  // }, 1000)
                  this._imageRatio = this.image.width / this.image.height
                  this.OnResize(0, 0, this.width, this.height)
              }
              // this.image.width = this.width
              // this.image.height = this.height
          }
          const files = fileInput.files
          if (files && files.length > 0) {
              this._selectedFile = files[0]
              reader.readAsDataURL(files[0])
          } else {
              this.image.src = ''
          }
          this.popup.Display(this.boardContainer, {
              X: 30,
              Y: 30
          })
      }
      fileInput.click()
  }
}
