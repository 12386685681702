import {
  IDrawStyle,
  DrawType,
  IDrawTool,
  DrawShape,
  IPosition
} from './Anotate'

export interface ILine {
  UserId: string;
  Style: IDrawStyle;
  Type: DrawType;
  X1: number;
  Y1: number;
  X2: number;
  Y2: number;
}

export class Line implements IDrawTool {
  tempObj: ILine = {
    Style: {
      brushColor: '#f8920f',
      brushSize: 5
    },
    Type: DrawType.Line,
    X1: -1,
    Y1: -1,
    X2: -1,
    Y2: -1,
    UserId: ''
  };

  mouseX: number = 0;
  mouseY: number = 0;
  canvas: HTMLCanvasElement;
  context: CanvasRenderingContext2D;
  private mouseDown = false;
  constructor (canvas: HTMLCanvasElement, style: IDrawStyle) {
    this.canvas = canvas
    this.tempObj.Style = style
    this.context = <CanvasRenderingContext2D> this.canvas.getContext('2d')
  }

  StartDrawing (style: IDrawStyle, pos: IPosition) {
    try {
      this.mouseDown = true
      this.tempObj.X1 = pos.X - this.canvas.offsetLeft
      this.tempObj.Y1 = pos.Y - this.canvas.offsetTop
      this.tempObj.Style = Object.assign({}, style)
    } catch (ex) {
      console.log('line.onMouseDown', ex)
    }
  }

  FinishDrawing () {
    try {
      if (!this.mouseDown) return
      // this.save();
      this.Draw(this.tempObj)
      this.mouseDown = false
      // this.Clear();
    } catch (ex) {
      console.log('line.onMouseUp', ex)
    }
  }

  UpdatePositions (pos: IPosition) {
    try {
      if (!this.mouseDown) return
      this.context.clearRect(0, 0, this.canvas.width, this.canvas.height)

      this.tempObj.X2 = pos.X - this.canvas.offsetLeft
      this.tempObj.Y2 = pos.Y - this.canvas.offsetTop
      this.Draw(this.tempObj)
    } catch (ex) {
      console.log('line.onMouseMove', ex)
    }
  }

  Draw (obj: ILine) {
    try {
      if (obj.X1 === -1 || obj.X2 === -1 || obj.Y1 === -1 || obj.Y2 === -1) {
        return
      }
      this.context.beginPath()
      this.context.moveTo(obj.X1, obj.Y1)
      this.context.lineTo(obj.X2, obj.Y2)
      this.context.lineJoin = this.context.lineCap = 'round'
      this.context.globalCompositeOperation = 'source-over'
      this.context.strokeStyle = obj.Style.brushColor
      this.context.lineWidth = obj.Style.brushSize
      this.context.stroke()
      this.context.closePath()
    } catch (ex) {
      console.log('line.onMouseDown', ex)
    }
  }

  GetShape (): DrawShape {
    return { ...this.tempObj }
  }

  GetStartPosition (): IPosition {
    return {
      X: this.tempObj.X1,
      Y: this.tempObj.Y1
    }
  }

  Clear () {
    this.tempObj.X1 = -1
    this.tempObj.X2 = -1
    this.tempObj.Y1 = -1
    this.tempObj.Y2 = -1
  }

  GetEndPosition (): IPosition {
    return {
      X: this.tempObj.X2,
      Y: this.tempObj.Y2
    }
  }
}
