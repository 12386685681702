export interface IResizerConfig {
    container: HTMLDivElement,
    minWidth: number,
    maxWidth: number,
    minHeight: number,
    maxHeight: number,
    onResize: (x: number, y: number, width: number, height: number) => void
}

export class Resizer {
    private _htmlResizeElement: HTMLDivElement
    private _x: number;
    private _y: number;
    private _width: number;
    private _height: number;
    private _minWidth: number;
    private _maxWidth: number;
    private _minHeight: number;
    private _maxHeight: number;
    private onResize: (x: number, y: number, width: number, height: number) => void
    constructor (config: IResizerConfig) {
        this._htmlResizeElement = config.container
        this._height = -1
        this._width = -1
        this._x = -1
        this._y = -1
        this._minWidth = config.minWidth ? config.minWidth : -1
        this._maxWidth = config.maxWidth ? config.maxWidth : -1
        this._minHeight = config.maxHeight ? config.maxHeight : -1
        this._maxHeight = config.maxHeight ? config.maxHeight : -1
        this._init()
        this.onResize = config.onResize
    }

    private _init () {
        this._htmlResizeElement.classList.add('resizer')
        this._htmlResizeElement.classList.add('center')

        this._htmlResizeElement.classList.add('resizable')
        const resizer = document.createElement('div')
        resizer.classList.add('helper')
        this._htmlResizeElement.appendChild(resizer)
        resizer.addEventListener('mousedown', this.initDrag, false)
    }

    private initDrag = (e: MouseEvent) => {
        this._x = e.clientX
        this._y = e.clientY
        const defaultView = document.defaultView
        if (defaultView != null) {
            this._width = parseInt(defaultView.getComputedStyle(this._htmlResizeElement).width, 10)
            this._height = parseInt(defaultView.getComputedStyle(this._htmlResizeElement).height, 10)
        }

        document.documentElement.addEventListener('mousemove', this.doDrag, false)
        document.documentElement.addEventListener('mouseup', this.stopDrag, false)
    }

    private doDrag = (e: MouseEvent) => {
        let width = this._width + e.clientX - this._x
        let height = this._height + e.clientY - this._y

        width = (this._minWidth !== -1 && width < this._minWidth) ? this._minWidth : width
        height = (this._minHeight !== -1 && height < this._minHeight) ? this._minHeight : height
        width = (this._maxWidth !== -1 && width > this._maxWidth) ? this._maxWidth : width
        height = (this._maxHeight !== -1 && height > this._maxHeight) ? this._maxHeight : height

        this._htmlResizeElement.style.width = width + 'px'
        this._htmlResizeElement.style.height = height + 'px'

        this.onResize(this._x, this._y, width, height)
    }

    private stopDrag = () => {
        document.documentElement.removeEventListener('mousemove', this.doDrag, false)
        document.documentElement.removeEventListener('mouseup', this.stopDrag, false)
    }
}
