import {
    IDrawStyle,
    DrawType,
    IDrawTool,
    DrawShape,
    IPosition
} from './Anotate'

export interface IFreeDraw {
  UserId: string;
  Style: IDrawStyle;
  Type: DrawType;
  Positions: Array<IPosition>;
}
export enum FreeDrawStrokeType {
  DRAW,
  ERASE,
}

export class FreeDraw implements IDrawTool {
  tempObj: IFreeDraw = {
      UserId: '',
      Style: {
          brushColor: '#f8920f',
          brushSize: 5
      },
      Type: DrawType.FreeDraw,
      Positions: []
  };

  canvas: HTMLCanvasElement;
  context: CanvasRenderingContext2D;
  lastCursorX: number = 0;
  lastCursorY: number = 0;
  isMouseDown: Boolean = false;
  constructor (
      canvas: HTMLCanvasElement,
      strokeType: FreeDrawStrokeType,
      style: IDrawStyle
  ) {
      this.canvas = canvas
      this.tempObj.Style = style
      this.context = <CanvasRenderingContext2D> this.canvas.getContext('2d')
      // this.RegisterEvents();
      this.SetStrokeType(strokeType)
  }

  private Stroke = (style: IDrawStyle) => {};

  private SetStrokeType (type: FreeDrawStrokeType) {
      if (type === FreeDrawStrokeType.ERASE) {
          this.tempObj.Type = DrawType.Eraser
          this.Stroke = (style: IDrawStyle) => {
              this.context.globalCompositeOperation = 'destination-out'
          }
      } else {
          this.Stroke = (style: IDrawStyle) => {
              this.context.globalCompositeOperation = 'source-over'
              this.context.strokeStyle = style.brushColor
              this.context.lineWidth = style.brushSize
          }
      }
  }

  StartDrawing (style: IDrawStyle, pos: IPosition) {
      try {
          this.tempObj.Style = Object.assign({}, style)
          this.lastCursorX = pos.X
          this.lastCursorY = pos.Y
          this.tempObj.Positions = []
          this.context.closePath()
          this.context.beginPath()
          this.tempObj.Positions.push(pos)
      } catch (ex) {
          console.log('freeDraw.onMouseDown', ex)
      }
  }

  FinishDrawing = () => {
      try {
          this.context.closePath()
          this.lastCursorX = -1
          this.lastCursorY = -1
      // this.tempObj.positions = [];
      } catch (ex) {
          console.log('freeDraw.onMouseUp', ex)
      }
  };

  UpdatePositions (pos: IPosition) {
      try {
          const currentCursorX = pos.X
          const currentCursorY = pos.Y
          this.tempObj.Positions.push({ X: pos.X, Y: pos.Y })

          this.context.lineTo(currentCursorX, currentCursorY)
          this.context.lineJoin = this.context.lineCap = 'round'
          this.Stroke(this.tempObj.Style)
          this.context.stroke()

          this.lastCursorX = currentCursorX
          this.lastCursorY = currentCursorY
      } catch (ex) {
          console.log('freeDraw.onMouseMove', ex)
      }
  }

  GetShape (): DrawShape {
      return { ...this.tempObj }
  }

  Draw (shape: IFreeDraw) {
      this.context.moveTo(shape.Positions[0].X, shape.Positions[0].Y)
      this.context.beginPath()
      for (let i = 0; i < shape.Positions.length; i++) {
          this.context.lineTo(shape.Positions[i].X, shape.Positions[i].Y)
          this.context.strokeStyle = shape.Style.brushColor
          this.context.lineWidth = shape.Style.brushSize
          this.Stroke(shape.Style)
          this.context.stroke()
      }
      this.context.closePath()
  }

  GetStartPosition (): IPosition {
      return {
          X: this.tempObj.Positions[0].X,
          Y: this.tempObj.Positions[0].Y
      }
  }

  GetEndPosition (): IPosition {
      const arr = this.tempObj.Positions
      return {
          X: arr[arr.length - 1].X,
          Y: arr[arr.length - 1].Y
      }
  }
}
