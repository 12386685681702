import { Whiteboard, IWhiteboardConfig } from './Whitebord'

declare global {
  interface Window {
    WhiteBoard: typeof Whiteboard;
  }
}
// var config: IWhiteboardConfig = {
//   Width: 600,
//   Container: <HTMLDivElement>document.getElementById("whiteboardContainer"),
//   Height: 500,
//   Url: "ws://localhost:8080/",
//   SessionId: GetUrlParamValue(location.href, "sessionid"),
//   BrushColors: ["blue", "green", "red", "black"],
//   Bru
// };
function GetUrlParamValue (url: string, key: string) {
  const url1 = new URL(url)
  const value = url1.searchParams.get(key)
  return value || ''
}
// window.WhiteBoard = new Whiteboard(config);
window.WhiteBoard = Whiteboard
