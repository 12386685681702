import { Replayer } from 'rrweb'
import { IConnectionMessage, IConnectionMessageType } from '../connection/ConnectionManager'
import 'rrweb/dist/rrweb-all.min.css'

export interface ICobrowseReceiverConfig{
    UserId: string;
    SessionId: string;
    Container: HTMLDivElement
}
export class CobrowseReceiver {
    #sessionId: string
    #userId: string
    #container: HTMLDivElement
    #isPlayerStarted: boolean = false
    #replayer: Replayer|undefined
    #incomingEvents: Array<any> = []
    #domMessageChunks: Array<any> = []
    #width:number = -1
    #height:number =-1

    constructor (config: ICobrowseReceiverConfig) {
        this.#userId = config.UserId
        this.#sessionId = config.SessionId
        this.#container = config.Container
    }

    startPlayer = () => {
        this.#replayer = new Replayer([], {
            root: this.#container,
            liveMode: true,
            // unpackFn: rrweb.unpack,
            mouseTail: false
        })
        this.#replayer.on('start', (payload) => {
            console.log('Started Playing')
            // if (this.#width > 0 && this.#height > 0) {
            //     const ife = document.querySelectorAll('.replayer-wrapper iframe')[0] as HTMLIFrameElement

            //     ife.style.width = this.#width + 'px'
            //     ife.style.height = this.#height + 'px'
            // }
        })
        this.#replayer.on('event-cast', (payload) => {
            console.log('Event Playing')
        })
        this.#replayer.startLive()
    }

    resize = (width: number, height: number) => {
        this.#width = width
        this.#height = height
    }

    OnMessage = (message: IConnectionMessage) => {
        if (message.Type === IConnectionMessageType.COBROWSEDATA) {
            if (message.Data.type === 4) {
                // message.Data.data.width = 500
                // message.Data.data.height = 500
            }
            this.#incomingEvents.push(message.Data)

            if (this.#isPlayerStarted === false) {
                this.startPlayer()
                this.#isPlayerStarted = true
            }

            // if the event has scroll data
            // if (message.Data?.data?.source === 3) {
            //     return
            // }

            this.#replayer?.addEvent(message.Data)
        } else if (message.Type === IConnectionMessageType.COBROWSE) {
            this.#domMessageChunks.push(message.Data.chunkData)

            if (message.Data.hasMoreChunks === false) {
                const chunk = JSON.parse(this.#domMessageChunks.join(''))
                this.#domMessageChunks = []

                if (this.#isPlayerStarted === false) {
                    this.startPlayer()
                    this.#isPlayerStarted = true
                }

                // this._replayer?.rebuildFullSnapshot(chunk, true)
                this.#replayer?.addEvent(chunk)
            }
        }
    }
}
