import { Draggable } from './Dragable'
import { IPosition } from '../anotate/Anotate'
import { DragIcon, DoneIcon, CloseIcon } from '../assets/Icons'
import { Resizer } from './Resizer'
type ActionType = 'CLOSE' | 'SUBMIT' | 'DRAG';

export interface IPopupContainer {
  BoardContainer: HTMLDivElement;
  Content: HTMLElement;
  Width: number | null;
  Height: number | null;
  EnableResize: boolean | null;
}
export interface ISize {
  Height: number;
  Width: number;
}
export class PopupContainer {
  private width: number = 150;
  private height: number = 150;
  private popupWindow: HTMLDivElement = document.createElement('div');
  private popupContainer: HTMLDivElement = document.createElement('div');
  private buttonsContainer: HTMLDivElement = document.createElement('div');
  private popupContent: HTMLDivElement = document.createElement('div');
  private handler: HTMLElement;
  private boardContainer: HTMLDivElement;
  private drag: Draggable;
  private resize: Resizer | null = null;
  constructor (config: IPopupContainer) {
      this.popupWindow.classList.add('popup-window')
      this.popupContainer.classList.add('popup-container')
      this.buttonsContainer.classList.add('popup-buttons')
      this.boardContainer = config.BoardContainer
      this.popupWindow.appendChild(this.buttonsContainer)
      // this.popupContainer.appendChild(this.popupContent)
      this.popupWindow.appendChild(this.popupContainer)
      this.popupContainer.appendChild(config.Content)
      this.popupContainer.style.overflow = 'hidden'
      // this.popupContainer.style.resize = 'both'

      if (config.EnableResize) {
          this.popupContainer.style.width = config.Width + 'px'
          this.popupContainer.style.height = config.Height + 'px'
          this.resize = new Resizer({
              container: this.popupContainer,
              minHeight: 150,
              minWidth: 150,
              maxHeight: -1,
              maxWidth: -1,
              onResize: (x: number, y: number, width: number, height: number) => {
                  this.OnResize(x, y, width, height)
              }
          })
      }

      this.handler = this.GetActionButton(DragIcon, 'DRAG')
      this.handler.style.cursor = 'grab'
      this.buttonsContainer.appendChild(this.handler)

      const submitButton = this.GetActionButton(DoneIcon, 'SUBMIT')
      submitButton.onclick = () => {
          this.OnSubmit()
      }
      this.buttonsContainer.appendChild(submitButton)

      const cancelButton = this.GetActionButton(CloseIcon, 'CLOSE')
      cancelButton.onclick = () => {
          this.OnCancel()
      }
      this.buttonsContainer.appendChild(cancelButton)

      this.drag = new Draggable({
          Container: this.popupWindow,
          Handler: this.handler,
          ParentContainer: this.boardContainer
      })
      this.drag.MoveTo({ X: 100, Y: 100 })
  }

  OnSubmit () { }
  OnCancel () { }

  private BuildUI () { }
  GetSize (): ISize {
      return {
          Width: this.width,
          Height: this.height
      }
  }

  GetPosition (): IPosition {
      return this.drag.GetPosition()
  }

  GetActionButton (icon: string, type: ActionType): HTMLAnchorElement {
      const a = document.createElement('a')
      a.classList.add('popup-btn')
      const i = document.createElement('i')
      i.classList.add('material-icons')
      i.innerHTML = icon
      a.appendChild(i)
      return a
  }

  Remove () {
      this.popupWindow.remove()
  }

  MoveTo (pos: IPosition) {
      this.drag.MoveTo(pos)
  }

  Display (container: HTMLElement, pos: IPosition) {
      container.appendChild(this.popupWindow)
      this.MoveTo(pos)
  }

  OnResize (x: number, y: number, width: number, height: number) {}
}
