import {
    IConnectionManagerConfig
} from './ConnectionManager'

export class WebSocketConnection {
  url: string = '';
  private _config: IConnectionManagerConfig;
  private _serverId: number
  connection: WebSocket;
  _waitTimeToReconnect: number = 600;
  OnReceive (event: MessageEvent) {}
  OnClose (event: CloseEvent): void {}
  OnError (event: Event): void {}
  OnMessage (event: MessageEvent): void {}
  OnOpen (event: Event): void {}

  constructor (config: IConnectionManagerConfig) {
      this._config = config
      this._serverId = config.ServerId
      this._waitTimeToReconnect = config.WaitTimeToReconnect ? config.WaitTimeToReconnect : 1000
      const url = this._getUrl(false)
      console.log('Connecting to ' + url)
      this.connection = new WebSocket(url)
      this.RegisterEvents()
  }

  private _getUrl (isFailover:boolean):string {
      let id = this._serverId - 1
      const srvs = this._config.Servers
      if (id < srvs.length && id > -1) {
          if (isFailover) {
              id = ++id % srvs.length
          }
          this._serverId = id + 1
          return srvs[id]
      } else {
          throw new Error('Invalid serverId, Make sure servers and serverId are configured')
      }
  }

  connect (url:string) {
      this.connection = new WebSocket(url)
      this.RegisterEvents()
  }

  Send (message: string) {
      this.connection.send(message)
  }

  RegisterEvents () {
      const that = this
      // An event listener to be called when the connection is closed.
      this.connection.onclose = function (event: CloseEvent) {
          console.error('server connection closed')
          setTimeout(() => {
              if (
                  that.connection.readyState === WebSocket.CLOSED ||
          that.connection.readyState === WebSocket.CLOSING
              ) {
                  const url = that._getUrl(true)
                  console.log('Reconnecting to ' + url)
                  that.connection = new WebSocket(url)
                  that.RegisterEvents()
              }
          }, that._waitTimeToReconnect)

          that.OnClose(event)
      }
      // An event listener to be called when an error occurs.
      this.connection.onerror = function (event: Event) {
          if (
              that.connection.readyState === WebSocket.CLOSED ||
        that.connection.readyState === WebSocket.CLOSING
          ) {
              const url = that._getUrl(true)
              console.log('Reconnecting to ' + url)
              that.connection = new WebSocket(url)
              that.RegisterEvents()
          }
      }
      // An event listener to be called when a message is received from the server.
      this.connection.onmessage = function (event: MessageEvent) {
          that.OnMessage(event)
      }
      // An event listener to be called when the connection is opened.
      this.connection.onopen = function (event: Event) {
          console.log('Connected to server.')
          that.OnOpen(event)
      }
  }
}
