import {
  IDrawStyle,
  DrawType,
  IDrawTool,
  DrawShape,
  IPosition
} from './Anotate'

export interface IRectangle {
  UserId: string;
  Style: IDrawStyle;
  Type: DrawType;
  X1: number;
  Y1: number;
  X2: number;
  Y2: number;
}

export class Rectangle implements IDrawTool {
  tempObj: IRectangle = {
    UserId: '',
    Style: {
      brushColor: '#f8920f',
      brushSize: 5
    },
    Type: DrawType.Rectangle,
    X1: -1,
    Y1: -1,
    X2: -1,
    Y2: -1
  };

  canvas: HTMLCanvasElement;
  context: CanvasRenderingContext2D;
  constructor (canvas: HTMLCanvasElement, style: IDrawStyle) {
    this.canvas = canvas
    this.tempObj.Style = style
    this.context = <CanvasRenderingContext2D> this.canvas.getContext('2d')
  }

  StartDrawing (style: IDrawStyle, pos: IPosition) {
    try {
      this.context.closePath()
      this.context.beginPath()
      this.tempObj.X1 = pos.X // - rect.left;
      this.tempObj.Y1 = pos.Y // - rect.top;
      this.tempObj.X2 = -1
      this.tempObj.Y2 = -1
      this.tempObj.Style = Object.assign({}, style)
    } catch (ex) {
      console.log('rectangle.onMouseDown', ex)
    }
  }

  UpdatePositions (pos: IPosition) {
    try {
      this.context.clearRect(0, 0, this.canvas.width, this.canvas.height)

      this.tempObj.X2 = pos.X // - rect.left;
      this.tempObj.Y2 = pos.Y // - rect.top;
      // annotCanvasContext.clearRect(0, 0, annotCanvas.width, annotCanvas.height);
      // annotCanvasContext.strokeStyle = 'rgba(0, 0, 0, 1)';
      this.Draw(this.tempObj)
    } catch (ex) {
      console.log('rectangle.onMouseMove', ex)
    }
  }

  FinishDrawing (pos: IPosition) {
    try {
      if (this.tempObj.X1 !== -1 && this.tempObj.Y1 !== -1) {
        this.Draw(this.tempObj)
      }
    } catch (ex) {
      console.log('rectangle.onMouseUp', ex)
    }
  }

  Draw (obj: IRectangle) {
    try {
      if (obj.X1 === -1 || obj.X2 === -1 || obj.Y1 === -1 || obj.Y2 === -1) {
        return
      }
      this.context.strokeStyle = obj.Style.brushColor
      this.context.lineJoin = this.context.lineCap = 'round'
      this.context.lineWidth = obj.Style.brushSize
      this.context.strokeRect(obj.X1, obj.Y1, obj.X2 - obj.X1, obj.Y2 - obj.Y1)
    } catch (ex) {
      console.log('rectangle.onMouseDown', ex)
    }
  }

  GetShape (): DrawShape {
    return { ...this.tempObj }
  }

  GetStartPosition (): IPosition {
    return {
      X: this.tempObj.X1,
      Y: this.tempObj.Y1
    }
  }

  GetEndPosition (): IPosition {
    return {
      X: this.tempObj.X2,
      Y: this.tempObj.Y2
    }
  }
}
