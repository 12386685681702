
export interface IFileUploadServerUrl {
    post:string,
    retrieval: string
}

export interface IFileServiceConfig {
    Servers: Array<IFileUploadServerUrl>;
    UserId: string;
}

interface HttpResponse<T> extends Response {
    parsedBody?: T;
  }

interface IRequestInfo {
    Url: string;
    Data: any;
    UserData: any;
}

export interface IResponseData<T> {
    /**
     * Response data from server
     */
    response: T;
    /**
     * Extra user data from the request to callback for reference
     */
    userObject: any
}

type XMLHttpRequestResponseType = '' | 'arraybuffer' | 'blob' | 'document' | 'json' | 'text';

export interface HttpRequestInfo {
    /**
     * Request URL
     */
    url: string;
    /**
     * Http method, GET default
     */
    method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
    /**
     * Request arguments/request body, { } by default
     */
    requestArgs?: any;
    /**
     * Extra user data from the request to callback for reference, null by default
     */
    userObject?: any;
    /**
     * Retry the request, 0 by default
     */
    retry?: number;
    /**
     * Request timeout, 10000 by default
     */
    timeout?: number;
    /**
     * To log the request respones time and size, false by default
     */
    log?: boolean;
    /**
     * Request headers
     */
    header?: Object;
    /**
     * FormData for file upload
     */
    formData?: FormData;
    /**
     * response type, by default 'text'
     */
    responseType?: XMLHttpRequestResponseType;
}

export interface FileServerResponse {
    /**
     * Upload success flag
     */
    isSuccess: boolean;
    /**
     * Message
     */
    message: string;
    /**
     * Result
     */
    result: {
        /**
         * File content type
         */
        contentType: string;
        /**
         * Conversation ID
         */
        conv_id: string;
        /**
         * Download URL
         */
        downloadURL: string;
        /**
         * Interaction ID
         */
        interaction_id: string;
        /**
         * Original file name
         */
        original_name: string;
        /**
         * Size of file
         */
        size: number;
        /**
         * File stream url
         */
        streamURL: string;
    };
}

/**
 * File Service
 */

export class FileService {
    private _config: IFileServiceConfig;
    private _userId: string;
    private _serverId = 0;

    constructor (config: IFileServiceConfig) {
        // assign the user data
        this._config = config
        this._userId = config.UserId
    }

    /**
     * To upload file to file server
     *
     * @param {File} file
     * @param {String} conversationId
     */
    async uploadFile (file: File, sessionId:string, id: string): Promise<IResponseData<FileServerResponse|null>> {
        try {
            const formData = new FormData()
            formData.append('file', file)
            formData.append('interaction_id', id)
            formData.append('organization_id', 'prod')
            formData.append('conv_id', sessionId)
            formData.append('uploaded_by', this._userId)
            formData.append('mimetype', file.type)

            const url = this._config.Servers[0].post

            // upload the file
            const req: IResponseData<FileServerResponse> = await FileService.sendRequest({
                url: url + '/upload',
                method: 'POST',
                responseType: 'json',
                formData,
                timeout: 60000
            })
            console.log(req.response)

            return req
        } catch (error) {
            console.error('Exception in fileService.uploadFile', error)
        }
        return {
            response: {
                isSuccess: false,
                message: 'ERROR',
                result: {
                    contentType: '',
                    conv_id: '',
                    interaction_id: '',
                    streamURL: '',
                    downloadURL: '',
                    original_name: '',
                    size: 0
                }
            },
            userObject: {}
        }
    }

    async http<T> (
        request: IRequestInfo
    ): Promise<HttpResponse<T>> {
        const response: HttpResponse<T> = await fetch(request.Url,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(request.Data)
            }
        )
        response.parsedBody = await response.json()
        return response.json()
    }

    /**
     * A static function to send a http request
     * @param requestInfo request object of type HttpRequestInfo
     */
    public static async sendRequest<T> (requestInfo: HttpRequestInfo): Promise<T | any> {
        try {
            const url = requestInfo.url || ''
            const method = requestInfo?.method || 'GET'
            const requestArgs = requestInfo?.requestArgs || {}
            const userObject = requestInfo?.userObject || null
            let retry = requestInfo?.retry || 0
            const timeout = requestInfo?.timeout || 20000
            const log = requestInfo?.log || false
            const headers = requestInfo?.header || []
            const formData = requestInfo?.formData || null
            const responseType = requestInfo.responseType || 'text'
            // check if the url is set
            if (url.length === 0) {
                return Promise.reject(new Error('HttpClient: sendRequest - URLs are not available, please set the URLs using [setUrls] method'))
            }
            return new Promise((resolve, reject) => {
                // create an instance of XMLHttpRequest
                const xhr = new XMLHttpRequest()
                // prepare the request arguments
                const params = formData || (requestArgs !== null ? JSON.stringify(requestArgs) : null)
                // open XHR
                xhr.open(method, url, true)
                // set the credential to false to CORS
                xhr.withCredentials = false
                // set the headers if any
                if (Object.keys(headers).length > 0) {
                    for (const [key, value] of Object.entries(headers)) {
                        xhr.setRequestHeader(key, value)
                    }
                }
                // set the response type if provided
                xhr.responseType = responseType
                // time in milliseconds
                xhr.timeout = timeout
                // XMLHttpRequest timed out
                xhr.ontimeout = () => {
                    console.error('error', 'Error: sendRequest', `The request for [${url}] is timed out`)
                    reject(new Error(`The request for [${url}] is timed out`))
                }
                // listen to on load event
                xhr.onload = async () => {
                    // log the response if needed
                    if (log) {
                        console.log(`HttpClient: [${url}] response received, readyState=${xhr.readyState}, status=${xhr.status}`)
                    }
                    // success status
                    if (xhr.readyState === 4) {
                        // It's done, what happened?
                        if (xhr.status >= 200 && xhr.status < 300) {
                            // check the response header
                            if (xhr.getResponseHeader('content-type') !== 'text/html') {
                                try {
                                    // return the response with user object
                                    resolve({
                                        response: xhr.response,
                                        userObject
                                    })
                                } catch (error) {
                                    console.error('error', 'Error: sendRequest', error)
                                    return Promise.reject(new Error(`${error}`))
                                }
                            } else {
                                // Something went wrong?
                                console.error('error', 'Error: sendRequest', `Something went wrong during the transaction for [${url}], status=${xhr.status}`)
                                reject(new Error(`Something went wrong during the transaction for [${url}], responseText=${xhr.responseText}`))
                            }
                        } else if (xhr.status === 0 || xhr.status === 500) {
                            // check if retry is 0 then do not decrement
                            if (retry > 0) {
                                retry = --retry
                            }
                            // check if the method retry exceeded
                            if (retry === 0) {
                                // out of retries
                                console.error('error', 'Error: sendRequest', `An error has occurred during the transaction for [${url}], status=${xhr.status}`)
                                reject(new Error(`An error has occurred during the transaction for [${url}], status=${xhr.status}`))
                                return
                            }
                            // recurse if we still have retries
                            resolve(this.sendRequest({ ...requestInfo, retry }))
                        } else {
                            console.error('error', 'Error: sendRequest', `Unhandled response status - ${xhr.status}`)
                            reject(new Error(`Unhandled response status - ${xhr.status}`))
                        }
                    }
                }
                // listen to on error event
                xhr.onerror = async () => {
                    console.error(`Error: sendRequest An error has occurred during the transaction for [${url}], status=${xhr.status}`)
                    reject(new Error(`An error has occurred during the transaction for [${url}], status=${xhr.status}`))
                }
                // send the request
                xhr.send(params)
                // log the request if needed
                if (log) {
                    console.log('debug', `HttpClient: [${url}] request`)
                }
            })
        } catch (error) {
            console.error('Error: sendRequest', error)
            return Promise.reject(new Error(`${error}`))
        }
    }
}
