import { IPosition } from '../anotate/Anotate'

export interface IDraggaleConfig {
  Container: HTMLElement;
  Handler: HTMLElement | null;
  ParentContainer: HTMLElement;
}
export class Draggable {
  private container: HTMLElement;
  private handler: HTMLElement;
  private parentContainer: HTMLElement;

  private active: boolean = false;
  private currentX: number = -1;
  private currentY: number = -1;
  private initialX: number = -1;
  private initialY: number = -1;
  private xOffset: number = 0;
  private yOffset: number = 0;

  constructor (config: IDraggaleConfig) {
      this.container = config.Container
      if (!config.Handler) {
          this.handler = config.Container
      } else {
          this.handler = config.Handler
      }
      this.parentContainer = config.ParentContainer

      // this.handler.onmousedown = this.DragMouseDown;
      // this.container.addEventListener("touchstart", this.Start, false);
      // this.container.addEventListener("touchend", this.End, false);
      // this.parentContainer.addEventListener("touchmove", this.Drag, false);

      this.handler.addEventListener('mousedown', this.Start, false)
      window.addEventListener('mouseup', this.End, false)
      window.addEventListener('mousemove', this.Drag, false)
  }

  private Start = (e: MouseEvent) => {
      if (e.type === 'touchstart') {
          const touch = <TouchEvent>(<unknown>e)
          this.initialX = touch.touches[0].clientX - this.xOffset
          this.initialY = touch.touches[0].clientY - this.yOffset
      } else {
          this.initialX = e.clientX - this.xOffset
          this.initialY = e.clientY - this.yOffset
      }

      if (e.target === this.handler) {
          this.active = true
      }
  };

  private End = (e: MouseEvent) => {
      this.initialX = this.currentX
      this.initialY = this.currentY

      this.active = false
  };

  private Drag = (e: MouseEvent) => {
      if (this.active) {
          e.preventDefault()

          if (e.type === 'touchmove') {
              const touch = <TouchEvent>(<unknown>e)
              this.currentX = touch.touches[0].clientX - this.initialX
              this.currentY = touch.touches[0].clientY - this.initialY
          } else {
              this.currentX = e.clientX - this.initialX
              this.currentY = e.clientY - this.initialY
          }

          this.xOffset = this.currentX
          this.yOffset = this.currentY

          this.SetTranslate(this.currentX, this.currentY, this.container)
      }
  };

  private SetTranslate (xPos: number, yPos: number, el: HTMLElement) {
      // el.style.transform = "translate3d(" + xPos + "px, " + yPos + "px, 0)";
      el.style.left = xPos + 'px'
      el.style.top = yPos + 'px'
  }

  GetPosition (): IPosition {
      return {
          X: this.currentX,
          Y: this.currentY
      }
  }

  MoveTo (pos: IPosition) {
      this.SetTranslate(pos.X, pos.Y, this.container)
  }
}
