import { WebSocketConnection } from './WebSocket'
import { DrawShape, IPosition } from '../anotate/Anotate'
import { ITextBox } from '../anotate/TextBox'
import { IImageBox } from '../anotate/Image'

export interface IConnectionManagerConfig {
  Servers: Array<string>
  ServerId: number
  SessionId: string;
  Type: ConnectionType;
  OnReceive: Function;
  OnOpen: Function;
  UserId: string;
  WaitTimeToReconnect: number;
  // OnSessionData: Function;
  // OnClose: (param: CloseEvent) => void;
  // OnError: (param: Event) => void;
  // OnMessage: (param: MessageEvent) => void;
  // OnOpen: (param: Event) => void;
}

export enum DrawUpdateType {
  START = 1,
  UPDATE,
  FINISH,
}

export interface IConnectionMessage {
  SessionId: string;
  UserId: string;
  Type: IConnectionMessageType;
  Data: any;
}
export enum IConnectionMessageType {
  REGISTER = 1,
  ANOTATION,
  SESSION_DATA,
  USER_JOINED,
  DRAW_START,
  DRAW_UPDATE,
  DRAW_FINISH,
  USERLEFT,
  UNDO,
  REDO,
  COBROWSE,
  COBROWSEDATA,
  RESIZE
}
export enum ConnectionType {
  WEBSOCKET = 1,
}
export class ConnectionManager {
  private type: ConnectionType;

  private connection: WebSocketConnection;

  private sessionId: string;

  private userId: string;

  private Undo = (userId: string) => { };

  private Redo = (userId: string) => { };

  private _servers: Array<string>;

  private _serverId: number;

  private _onOpen: Function;

  OnReceive = (event: MessageEvent) => { };

  OnClose = (event: CloseEvent): void => { };

  OnError = (event: Event): void => { };

  ReceivedDrawingStart (message: IConnectionMessage) { }

  ReceivedDrawingUpdate (message: IConnectionMessage) { }

  ReceivedDrawingFinish (message: IConnectionMessage) { }

  ReceiveDrawing (userid: string, drawShape: DrawShape | ITextBox | IImageBox) { }

  UserJoined (userId: string) { }

  UserLeft (userId: string) { }

  OnSessionData (shape: DrawShape) {}

  OnCobrowseMessageReceived (message: IConnectionMessage) {}

  OnResize = (width:number, height:number) => {}

  private _getMessage (text:string):IConnectionMessage {
      let val = ''

      let i = 0
      const message: IConnectionMessage = {
          SessionId: '',
          UserId: '',
          Type: -1,
          Data: ''
      }
      try {
          while (i < text.length && text[i] !== ',') {
              val += text[i]
              i++
          }
          message.SessionId = val
          i++
          val = ''
          while (i < text.length && text[i] !== ',') {
              val += text[i]
              i++
          }
          message.UserId = val
          i++
          val = ''

          while (i < text.length && text[i] !== ',') {
              val += text[i]
              i++
          }
          message.Type = parseInt(val)
          i++
          val = ''

          message.Data = text.substr(i, text.length)
      } catch (ex) {
          console.error('Error while getting next csv value, text: ' + text, ex)
      }
      return message
  }

  constructor (config: IConnectionManagerConfig) {
      this.type = config.Type
      this.sessionId = config.SessionId
      this.userId = config.UserId
      this._serverId = config.ServerId
      this._servers = config.Servers
      this._onOpen = config.OnOpen
      this.connection = this.StartConnection(config)
  }

  private StartConnection (
      config: IConnectionManagerConfig
  ): WebSocketConnection {
      let connection
      if (config.Type === ConnectionType.WEBSOCKET) {
          connection = new WebSocketConnection(config)
          connection.OnReceive = this.OnReceive
          connection.OnClose = this.OnClose
          connection.OnError = this.OnError
          connection.OnMessage = this.OnMessage
          connection.OnOpen = this.OnOpen
          return connection
      }
      return this.connection
  }

  SendMessage (message: IConnectionMessage) {
      let msg = ''
      msg += message.SessionId
      msg += ',' + message.UserId
      msg += ',' + message.Type
      msg += ',' + JSON.stringify(message.Data)
      this.connection.Send(msg)
  }

  OnMessage = (event: MessageEvent): void => {
      console.log(`message received: ${event.data}`)
      const conMsg: IConnectionMessage = this._getMessage(event.data)
      this.HandleMessage(conMsg)
  };

  HandleMessage = (message: IConnectionMessage) => {
      try {
          if (message.Type === IConnectionMessageType.SESSION_DATA) {
              // data format will be in "user1,user2,user3|user1:{},user2"
              const arr = message.Data.split('|')
              const users = arr[0].split(',')
              for (let i = 0; i < users.length; i++) {
                  if (users[i] !== this.userId) {
                      this.UserJoined(users[i])
                  }
              }
              let drawings = arr[1]
              drawings = '[' + drawings + ']'
              const data = JSON.parse(drawings)
              if (data.length > 0) {
                  this.OnSessionData(data)
              }
          } else {
              message.Data = JSON.parse(message.Data)
          }
          if (message.Type === IConnectionMessageType.DRAW_START) {
              // this.UserJoined(message.UserId)
              this.ReceivedDrawingStart(message)
          } else if (message.Type === IConnectionMessageType.DRAW_UPDATE) {
              this.ReceivedDrawingUpdate(message)
          } else if (message.Type === IConnectionMessageType.DRAW_FINISH) {
              this.ReceivedDrawingFinish(message)
          } else if (message.Type === IConnectionMessageType.REGISTER) {
              this.UserJoined(message.UserId)
          } else if (message.Type === IConnectionMessageType.UNDO) {
              this.Undo(message.UserId)
          } else if (message.Type === IConnectionMessageType.REDO) {
              this.Redo(message.UserId)
          } else if (message.Type === IConnectionMessageType.ANOTATION) {
              // this.OnDrawingReceived(message);
              this.ReceiveDrawing(message.UserId, message.Data)
          } else if (message.Type === IConnectionMessageType.COBROWSE || message.Type === IConnectionMessageType.COBROWSEDATA) {
              this.OnCobrowseMessageReceived(message)
          } else if (message.Type === IConnectionMessageType.RESIZE) {
              this.OnResize(message.Data?.w, message.Data?.h)
          }
      } catch (ex) {
          console.error(ex)
      }
  }

  OnOpen = (param: Event): void => {
      this.SendMessage({
          Type: IConnectionMessageType.REGISTER,
          Data: {},
          SessionId: this.sessionId,
          UserId: this.userId
      })
      this._onOpen()
  };

  OnDrawingReceived (message: IConnectionMessage) { }

  SendDrawingStart (shape: DrawShape) {
      console.log('sending drawing start')
      this.SendMessage({
          UserId: this.userId,
          Type: IConnectionMessageType.DRAW_START,
          Data: shape,
          SessionId: this.sessionId
      })
  }

  SendDrawingUpdate (pos: Array<IPosition>) {
      console.log('sending drawing finish')
      this.SendMessage({
          UserId: this.userId,
          Type: IConnectionMessageType.DRAW_UPDATE,
          Data: pos,
          SessionId: this.sessionId
      })
  }

  SendDrawingFinish (shape: DrawShape) {
      console.log('sending drawing finish')
      this.SendMessage({
          UserId: this.userId,
          Type: IConnectionMessageType.DRAW_FINISH,
          Data: shape,
          SessionId: this.sessionId
      })
  }

  SendDrawing (drawing: DrawShape | ITextBox | IImageBox) {
      this.SendMessage({
          Type: IConnectionMessageType.ANOTATION,
          Data: drawing,
          UserId: this.userId,
          SessionId: this.sessionId
      })
  }
}
