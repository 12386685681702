import {
  IDrawStyle,
  DrawType,
  IDrawTool,
  DrawShape,
  IPosition
} from './Anotate'

export interface IArrow {
  UserId: string;
  Style: IDrawStyle;
  X1: number;
  Y1: number;
  X2: number;
  Y2: number;
  Type: DrawType;
}

export class Arrow implements IDrawTool {
  tempObj: IArrow = {
    UserId: '',
    Style: {
      brushColor: '#f8920f',
      brushSize: 5
    },
    Type: DrawType.Arrow,
    X1: -1,
    Y1: -1,
    X2: -1,
    Y2: -1
  };

  mouseX: number = 0;
  mouseY: number = 0;
  canvas: HTMLCanvasElement;
  context: CanvasRenderingContext2D;
  constructor (canvas: HTMLCanvasElement, style: IDrawStyle) {
    this.canvas = canvas
    this.tempObj.Style = style
    this.context = <CanvasRenderingContext2D> this.canvas.getContext('2d')
  }

  StartDrawing (style: IDrawStyle, pos: IPosition) {
    try {
      this.tempObj.X1 = pos.X - this.canvas.offsetLeft
      this.tempObj.Y1 = pos.Y - this.canvas.offsetTop
      this.tempObj.Style = Object.assign({}, style)
    } catch (ex) {
      console.log('arrow.onMouseDown', ex)
    }
  }

  FinishDrawing () {
    try {
      // this.save();
      this.Draw(this.tempObj)
    } catch (ex) {
      console.log('arrow.onMouseUp', ex)
    }
  }

  UpdatePositions (pos: IPosition) {
    try {
      this.context.clearRect(0, 0, this.canvas.width, this.canvas.height)

      this.tempObj.X2 = pos.X - this.canvas.offsetLeft
      this.tempObj.Y2 = pos.Y - this.canvas.offsetTop
      this.Draw(this.tempObj)
    } catch (ex) {
      console.log('arrow.onMouseMove', ex)
    }
  }

  GetShape (): DrawShape {
    return { ...this.tempObj }
  }

  Draw (obj: IArrow) {
    try {
      if (obj.X1 === -1 || obj.X2 === -1 || obj.Y1 === -1 || obj.Y2 === -1) {
        return
      }
      this.context.beginPath()
      this.context.moveTo(obj.X1, obj.Y1)
      this.context.lineTo(obj.X2, obj.Y2)
      this.context.strokeStyle = obj.Style.brushColor
      this.context.lineWidth = obj.Style.brushSize
      this.context.stroke()
      this.context.closePath()
      this.DrawArrowhead(obj.X1, obj.Y1, obj.X2, obj.Y2, obj)
    } catch (ex) {
      console.log('arrow.onMouseDown', ex)
    }
  }

  DrawArrowhead (x1: number, y1: number, x2: number, y2: number, obj: IArrow) {
    const PI2 = Math.PI * 2
    const dx = x2 - x1
    const dy = y2 - y1
    const arrowHeadWidth = obj.Style.brushSize * 2
    const arrowHeadHeight = obj.Style.brushSize * 4
    const radians = (Math.atan2(dy, dx) + PI2) % PI2
    this.context.save()
    this.context.beginPath()
    this.context.translate(x2, y2)
    this.context.rotate(radians)
    this.context.moveTo(obj.Style.brushSize, 0)
    this.context.lineTo(-arrowHeadHeight, arrowHeadWidth)
    this.context.lineTo(-arrowHeadHeight, -arrowHeadWidth)
    this.context.strokeStyle = obj.Style.brushColor
    this.context.lineWidth = obj.Style.brushSize
    this.context.closePath()
    this.context.fillStyle = obj.Style.brushColor
    this.context.fill()
    this.context.restore()
  }

  OnUpdate (shape: DrawShape) {}

  GetStartPosition (): IPosition {
    return {
      X: this.tempObj.X1,
      Y: this.tempObj.Y1
    }
  }

  GetEndPosition (): IPosition {
    return {
      X: this.tempObj.X2,
      Y: this.tempObj.Y2
    }
  }
}
