import { Rectangle, IRectangle } from './Rectangle'
import { Arrow, IArrow } from './Arrow'
import { Elipse, IElipse } from './Elipse'
import { FreeDraw, FreeDrawStrokeType, IFreeDraw } from './FreeDraw'
import { Line, ILine } from './Line'
import { ITextBox } from './TextBox'
import { IEraser } from './Eraser'
import { Drawing } from '../Whitebord'
import { IImageBox } from './Image'

export type DrawShape =
  | ILine
  | IArrow
  | IElipse
  | IFreeDraw
  | IRectangle
  | IEraser;

export interface IDrawTool {
  StartDrawing: Function;
  UpdatePositions: Function;
  FinishDrawing: Function;
  GetShape: () => DrawShape;
  Draw: Function;
}

export enum DrawType {
  FreeDraw = 1,
  Elipse,
  Rectangle,
  Line,
  Arrow,
  Image,
  Textbox,
  Eraser,
  Disable
}
export type DrawTools = Line | Elipse | Rectangle | FreeDraw | Arrow;
export enum DrawEventType {
  startDrawing = 1,
  updatePositions,
  finishDrawing,
}

export interface IDrawStyle {
  brushColor: string;
  brushSize: number;
}

export interface IAnotateConfig {
  Container: HTMLDivElement;
  Height: number;
  Width: number;
  DefaultTool: DrawType;
  UserId: string;
}

export interface IPosition {
  X: number;
  Y: number;
}

export class Anotate {
  style: IDrawStyle = {
      brushColor: '#2196f1',
      brushSize: 5
  };

  isEnabled: boolean = false;
  #selectedDrawType: number = -1;
  #container: HTMLElement;
  #context: CanvasRenderingContext2D;
  #isPointer = false;
  #line: Line;
  #arraw: Arrow;
  #rectange: Rectangle;
  #ellipse: Elipse;
  #freeDraw: FreeDraw;
  #erasor: FreeDraw;
  #userId: string;

  redoArray: Array<any> = [];
  selectedTool: Line | Arrow | Elipse | Rectangle | FreeDraw;
  isMouseDown: boolean = false;
  TempAnnotations: Array<any> = [];
  canvas: HTMLCanvasElement;

  constructor (config: IAnotateConfig) {
      this.canvas = document.createElement('canvas')
      this.canvas.width = config.Width
      this.canvas.height = config.Height
      this.canvas.classList.add('self-board')
      this.#container = config.Container
      this.#userId = config.UserId
      config.Container.appendChild(this.canvas)

      this.#context = <CanvasRenderingContext2D> this.canvas.getContext('2d')
      this.selectedTool = this.GetTool(DrawType.FreeDraw)

      this.#line = new Line(this.canvas, this.style)
      this.#ellipse = new Elipse(this.canvas, this.style)
      this.#arraw = new Arrow(this.canvas, this.style)
      this.#rectange = new Rectangle(this.canvas, this.style)
      this.#erasor = new FreeDraw(
          this.canvas,
          FreeDrawStrokeType.ERASE,
          this.style
      )
      this.#freeDraw = new FreeDraw(
          this.canvas,
          FreeDrawStrokeType.DRAW,
          this.style
      )
      this.SelectAnnotationType(DrawType.FreeDraw)
      this.RegisterEvents()
  }

  OnNewShape (shape: DrawShape) {}
  OnStart (shape: DrawShape) {}
  OnUpdate (pos: IPosition) {}
  OnFinish (shape: DrawShape) {}
  SendAnootData () {}

  SelectAnnotationType (type: DrawType) {
      this.#selectedDrawType = type
      this.selectedTool = this.GetTool(type)
      // this.Enable()
  }

  GetTool (type: DrawType): DrawTools {
      this.Enable()
      if (type === DrawType.Line) {
          return new Line(this.canvas, this.style)
      } else if (type === DrawType.Elipse) {
          return new Elipse(this.canvas, this.style)
      } else if (type === DrawType.Arrow) {
          return new Arrow(this.canvas, this.style)
      } else if (type === DrawType.Rectangle) {
          return new Rectangle(this.canvas, this.style)
      } else if (type === DrawType.Eraser) {
          return new FreeDraw(this.canvas, FreeDrawStrokeType.ERASE, this.style)
      } else if (type === DrawType.Disable) {
          this.Disable()
      }
      return new FreeDraw(this.canvas, FreeDrawStrokeType.DRAW, this.style)
  }

  Disable () {
      try {
          this.isEnabled = false
          this.canvas.style.pointerEvents = 'none'
      } catch (ex) {
          console.log('annotation.disable', ex)
      }
  }

  private RegisterEvents () {
      const that = this
      this.RegisterMouseEvents()

      this.canvas.onpointerenter = (e: PointerEvent) => {
          if (e.pointerType !== 'pen') return
          e.preventDefault()
          that.#isPointer = true
      }
      this.canvas.onpointerdown = (e: PointerEvent) => {
          if (e.pointerType !== 'pen') return
          e.preventDefault()
          that.#isPointer = true
          that.DrawStart({
              X: e.clientX,
              Y: e.clientY
          })
      }
      this.canvas.onpointermove = (e: PointerEvent) => {
          if (!that.isMouseDown || e.pointerType !== 'pen') {
              // not mouse down
          } else {
              e.preventDefault()
              that.DrawUpdate({
                  X: e.clientX,
                  Y: e.clientY
              })
          }
      }
      this.canvas.onpointerup = this.canvas.onpointerout = (e: PointerEvent) => {
          if (e.pointerType !== 'pen') return
          e.preventDefault()
          that.DrawEnd({
              X: e.clientX,
              Y: e.clientY
          })
      }
  }

  private RegisterMouseEvents () {
      const that = this
      this.canvas.onmousedown = function (e: MouseEvent) {
          try {
              that.isMouseDown = true

              that.selectedTool.StartDrawing(
                  { ...that.style },
                  {
                      X: e.offsetX,
                      Y: e.offsetY
                  }
              )
              that.OnStart(that.selectedTool.GetShape())
          } catch (ex) {
              console.log('annotConvas.onmousedown', ex)
          }
      }

      this.canvas.onmouseup = function (e: MouseEvent) {
          try {
              const pos = {
                  X: e.offsetX,
                  Y: e.offsetY
              }
              that.selectedTool.FinishDrawing(pos)
              const shape = that.selectedTool.GetShape()

              that.isMouseDown = false
              that.OnNewShape(shape)
              that.OnFinish(shape)
              // that.Clear();
          } catch (ex) {
              console.log('this._drawCanvas.onmouseup', ex)
          }
      }

      this.canvas.onmousemove = function (e: MouseEvent) {
          try {
              if (!that.isMouseDown) {
                  return
              } else {
                  that.selectedTool.UpdatePositions({
                      X: e.offsetX,
                      Y: e.offsetY
                  })
                  that.OnUpdate(that.selectedTool.GetEndPosition())
              }
          } catch (ex) {
              console.log('this._drawCanvas.onmousemove', ex)
          }
      }

      this.canvas.onmouseout = function (e: MouseEvent) {
          try {
              const pos = {
                  X: e.offsetX,
                  Y: e.offsetY
              }

              if (!that.isMouseDown) {
                  // not mouse down
              } else {
                  that.selectedTool.FinishDrawing(pos)
                  const shape = that.selectedTool.GetShape()
                  that.OnNewShape(shape)
                  that.OnFinish(shape)
                  that.isMouseDown = false
              }
          } catch (ex) {
              console.log('this._drawCanvas.onmouseout', ex)
          }
      }
  }

  private RegisterTouchEvents () {
      const that = this
      this.canvas.ontouchstart = function (e: TouchEvent) {
          that.DrawStart({
              X: e.touches[0].clientX,
              Y: e.touches[0].clientY
          })
      }
      this.canvas.ontouchmove = function (e: TouchEvent) {
          that.DrawUpdate({
              X: e.touches[0].clientX,
              Y: e.touches[0].clientY
          })
      }
      this.canvas.ontouchend = function (e: TouchEvent) {
          that.DrawEnd({
              X: e.touches[0].clientX,
              Y: e.touches[0].clientY
          })
      }
      this.canvas.ontouchcancel = function (e: TouchEvent) {
          that.DrawEnd({
              X: e.touches[0].clientX,
              Y: e.touches[0].clientY
          })
      }
  }

  private DrawStart (pos: IPosition) {
      this.isMouseDown = true

      this.selectedTool.StartDrawing({ ...this.style }, pos)
      this.OnStart(this.selectedTool.GetShape())
  }

  private DrawUpdate (pos: IPosition) {
      if (!this.isMouseDown) {
          // not mouse click
      } else {
          this.selectedTool.UpdatePositions(pos)
          this.OnUpdate(this.selectedTool.GetEndPosition())
      }
  }

  private DrawEnd (pos: IPosition) {
      this.selectedTool.FinishDrawing(pos)

      const shape = this.selectedTool.GetShape()
      this.isMouseDown = false
      this.OnNewShape(shape)
      this.OnFinish(shape)
  }

  private WrapText (
      context: CanvasRenderingContext2D,
      text: string,
      x: number,
      y: number,
      maxWidth: number,
      lineHeight: number
  ) {
      const words = text.split(' ')
      let line = ''
      y += 50
      maxWidth += 50
      for (let n = 0; n < words.length; n++) {
          const testLine = line + words[n] + ' '
          const metrics = context.measureText(testLine)
          const testWidth = metrics.width
          if (testWidth > maxWidth && n > 0) {
              context.fillText(line, x, y)
              line = words[n] + ' '
              y += lineHeight
          } else {
              line = testLine
          }
      }
      context.fillText(line, x, y)
  }

  Clear () {
      this.#context.clearRect(0, 0, this.canvas.width, this.canvas.height)
  }

  Enable () {
      try {
          this.#selectedDrawType = DrawType.FreeDraw
          this.isEnabled = true
          this.canvas.style.pointerEvents = 'all'
      } catch (ex) {
          console.log('annotation.disable', ex)
      }
  }

  Draw (shape: Drawing) {
      if (shape.Type === DrawType.Line) {
          new Line(this.canvas, this.style).Draw(<ILine>shape)
      }
      if (shape.Type === DrawType.Elipse) {
          new Elipse(this.canvas, this.style).Draw(<IElipse>shape)
      }
      if (shape.Type === DrawType.Rectangle) {
          new Rectangle(this.canvas, this.style).Draw(<IRectangle>shape)
      }
      if (shape.Type === DrawType.Arrow) {
          new Arrow(this.canvas, this.style).Draw(<IArrow>shape)
      }
      if (shape.Type === DrawType.FreeDraw) {
          new FreeDraw(this.canvas, FreeDrawStrokeType.DRAW, this.style).Draw(
        <IFreeDraw>shape
          )
      }
      if (shape.Type === DrawType.Eraser) {
          new FreeDraw(this.canvas, FreeDrawStrokeType.ERASE, this.style).Draw(
        <IFreeDraw>shape
          )
      }
      if (shape.Type === DrawType.Textbox) {
          this.DrawText(<ITextBox>shape)
      } else if (shape.Type === DrawType.Image) {
          this.DrawImage(<IImageBox>shape)
      }
  }

  SetAnnotationStyle (style: IDrawStyle) {
      try {
          this.#context.strokeStyle = style.brushColor
          this.#context.lineWidth = style.brushSize
      } catch (ex) {
          console.log('annotation.setAnnotationSyle', ex)
      }
  }

  DrawImage (imagebox: IImageBox) {
      // image: HTMLImageElement, pos: IPosition, shape: ISize) {
      const image = document.createElement('img')
      image.src = imagebox.ImageData
      image.onload = () => {
          this.#context.drawImage(
              image,
              imagebox.Pos.X,
              imagebox.Pos.Y,
              imagebox.Shape.Width,
              imagebox.Shape.Height
          )
          image.remove()
      }
  }

  DrawText (textbox: ITextBox) {
      // } text: string, pos: IPosition, shape: ISize) {
      this.WrapText(
          this.#context,
          textbox.Text,
          textbox.Pos.X,
          textbox.Pos.Y,
          textbox.Shape.Width,
          25
      )
  }

  DrawShapes = (drawings: Array<Drawing>) => {
      drawings.forEach((drawing, i) => {
          if (drawing.Type === DrawType.Textbox) {
              this.DrawText(<ITextBox>drawing)
          } else if (drawing.Type === DrawType.Image) {
              this.DrawImage(<IImageBox>drawing)
          } else {
              this.Draw(<DrawShape>drawing)
          }
      })
  };

  ChangeBrushColor = (color: string) => {
      this.style.brushColor = color
  };

  ChangeBrushSize = (size: number) => {
      this.style.brushSize = size
  };

  changeSize = (width: number, height: number) => {
      this.canvas.width = width
      this.canvas.height = height
  }
}
