import { DrawType } from './anotate/Anotate'
import {
    BlockIcon, ElipseIcon, ArrowIcon, BrushSizeIcon, ColorPaletIcon, DeleteIcon,
    ErasorIcon, ImageAddIcon, LineIcon, PencilIcon,
    RectangleIcon, RedoIcon, TextIcon, UndoIcon
} from './assets/Icons'

export interface UIBuilderConfig {
    UserId: string;
    MainContainer: HTMLDivElement;
    BoardContainer: HTMLDivElement;
    AppContainer: HTMLDivElement;
    ToolboxContainer: HTMLDivElement;
    IsCobrowseEnabled: boolean
    Height: number;
    Width: number;
    BrushColors: Array<string>;
    BrushSize: number;
    OnUndo: () => void;
    OnRedo: () => void;
    OnBrushColorChange: (color: string) => void;
    OnBrushSizeChange: (size: number) => void;
}

export class UIBuilder {
    #userId: string;
    #mainContainer: HTMLDivElement;
    #appContainer: HTMLDivElement;
    #boardContainer: HTMLDivElement;
    #toolboxContainer: HTMLUListElement;
    #drawToolContainer: HTMLLIElement;

    #width: number;
    #height: number;
    #canvasWidth: number;
    #OnUndo = () => { };
    #OnRedo = () => { };
    #brushColors: Array<string> = [
        '#3F51B5',
        '#E91E63',
        '#4CAF50',
        '#F44336',
        'black',
        '#FF9800'
    ];

    #colorButtonIcon = document.createElement('i');
    #brushSize: number;
    #currentTool: HTMLAnchorElement;
    #OnBrushColorChange = (color: string) => { };
    #OnBrushSizeChange = (size: number) => { };
    constructor (config: UIBuilderConfig) {
        this.#userId = config.UserId
        this.#width = config.Width
        this.#canvasWidth = config.Width - 50
        this.#height = config.Height
        this.#mainContainer = config.MainContainer
        this.#appContainer = config.AppContainer
        this.#boardContainer = config.BoardContainer
        if (config.BrushColors && config.BrushColors.length > 0) {
            this.#brushColors = config.BrushColors
        }
        this.#brushSize = config.BrushSize ? config.BrushSize : 5
        this.#OnUndo = config.OnUndo
        this.#OnRedo = config.OnRedo
        this.#OnBrushColorChange = config.OnBrushColorChange
        this.#OnBrushSizeChange = config.OnBrushSizeChange

        this.#appContainer.style.width = this.#width + 'px'
        this.#appContainer.style.height = this.#height + 'px'

        this.#drawToolContainer = document.createElement('li')
        this.#currentTool = this.GetAnchorElement(PencilIcon)
        this.#toolboxContainer = document.createElement('ul')
        config.ToolboxContainer.appendChild(this.#toolboxContainer)
        config.ToolboxContainer.classList.add('toolbox')
        config.ToolboxContainer.style.pointerEvents = 'all'
        this.#mainContainer.appendChild(this.#appContainer)
        this.#appContainer.appendChild(config.ToolboxContainer)
        this.#appContainer.appendChild(this.#boardContainer)

        this.#toolboxContainer.classList.add('scrollbar')
        this.#boardContainer.className += 'board'
        this.#appContainer.className += 'white-board-container '

        this.#toolboxContainer.appendChild(this.GetColorPaletButton())
        this.#toolboxContainer.appendChild(this.GetBrushSizeButton())
        this.#toolboxContainer.appendChild(this.GetDrawingTools())
        this.#toolboxContainer.appendChild(
            this.GetToolboxIcon(ErasorIcon, DrawType.Eraser)
        )

        this.#toolboxContainer.appendChild(
            this.GetToolboxIcon(ImageAddIcon, DrawType.Image)
        )
        this.#toolboxContainer.appendChild(
            this.GetToolboxIcon(TextIcon, DrawType.Textbox)
        )
        this.#toolboxContainer.appendChild(this.GetUndoButton())
        this.#toolboxContainer.appendChild(this.GetRedoButton())
        // this.toolboxContainer.appendChild(this.GetAnchorElement(DeleteIcon));
    }

    private GetToolboxIcon (icon: string, type: DrawType): HTMLLIElement {
        const li = document.createElement('li')
        const a = this.GetButton(icon)
        a.onclick = () => {
            this.OnSelectTool(type)
            this.ChangeCurrentToolDisplay(type)
            const active = document.getElementsByClassName('active')
            // this.toolboxContainer.children()
            if (active.length > 0) {
                active[0].classList.remove('active')
            }
            a.classList.add('active')
        }
        li.appendChild(a)
        return li
    }

    private ChangeCurrentToolDisplay (type: DrawType) {
        try {
            this.#currentTool.remove()
            if (type === DrawType.FreeDraw) {
                this.#currentTool = this.GetAnchorElement(PencilIcon)
            } else if (type === DrawType.Arrow) {
                this.#currentTool = this.GetAnchorElement(ArrowIcon)
            } else if (type === DrawType.Elipse) {
                this.#currentTool = this.GetAnchorElement(ElipseIcon)
            } else if (type === DrawType.Eraser) {
                this.#currentTool = this.GetAnchorElement(ErasorIcon)
            } else if (type === DrawType.Line) {
                this.#currentTool = this.GetAnchorElement(LineIcon)
            } else if (type === DrawType.Rectangle) {
                this.#currentTool = this.GetAnchorElement(RectangleIcon)
            } else if (type === DrawType.Disable) {
                this.#currentTool = this.GetAnchorElement(BlockIcon)
            }
            this.#drawToolContainer.appendChild(this.#currentTool)
        } catch (e) {
            console.error(e)
        }
    }

    private GetAnchorElement (icon: string): HTMLAnchorElement {
        const a = document.createElement('a')
        const i = document.createElement('i')
        i.classList.add('material-icons')
        i.innerHTML = icon
        a.appendChild(i)
        return a
    }

    private GetUndoButton () {
        const li = document.createElement('li')
        const a = this.GetButton(UndoIcon)
        a.onclick = () => {
            this.#OnUndo()
        }
        li.appendChild(a)
        return li
    }

    private GetRedoButton () {
        const li = document.createElement('li')
        const a = this.GetButton(RedoIcon)
        a.onclick = () => {
            this.#OnRedo()
        }
        li.appendChild(a)
        return li
    }

    private GetClearAllButton () {
        const a = this.GetButton(DeleteIcon)
        a.onclick = () => {
            this.#OnRedo()
        }
        return a
    }

    private GetButton (icon: string) {
        const a = document.createElement('a')
        a.className += 'nav__link'
        const i = document.createElement('i')
        i.className += 'material-icons nav__icon'
        i.innerHTML = icon
        a.appendChild(i)
        return a
    }

    private GetBrushSizeButton () {
        const li = document.createElement('li')
        const a = this.GetButton(BrushSizeIcon)
        a.classList.add('brush-size')

        li.appendChild(a)
        const ul = document.createElement('ul')
        const li1 = document.createElement('li')
        const input = document.createElement('input')
        input.onclick = (e) => {
            const input = <HTMLInputElement>e.target
            this.#OnBrushSizeChange(parseInt(input.value))
        }
        input.type = 'range'
        input.min = '0'
        input.max = '30'
        input.value = this.#brushSize.toString()
        li1.appendChild(input)
        ul.appendChild(li1)

        li.appendChild(ul)
        return li
    }

    private GetToolBox (container: HTMLDivElement) {
        const ul = document.createElement('ul')
        ul.appendChild(this.GetColorPaletButton())
    }

    private GetColorPaletButton (): HTMLLIElement {
        const li = document.createElement('li')

        const a = document.createElement('a')
        const icon = document.createElement('i')
        icon.classList.add('material-icons')
        icon.innerHTML = ColorPaletIcon
        this.#colorButtonIcon = icon
        a.appendChild(icon)
        li.appendChild(a)

        const ul = document.createElement('ul')
        ul.classList.add('color-options')
        this.#brushColors.forEach((color, i) => {
            ul.appendChild(this.GetColorButton(color, icon))
        })
        li.appendChild(ul)
        return li
    }

    private GetColorButton (color: string, icon: HTMLElement): HTMLLIElement {
        const li = document.createElement('li')
        const a = document.createElement('a')
        const span = document.createElement('span')
        span.style.backgroundColor = color
        a.onclick = () => {
            this.#OnBrushColorChange(color)
            icon.style.color = color
        }
        a.appendChild(span)
        li.appendChild(a)
        return li
    }

    private GetDrawingTools (): HTMLLIElement {
        this.#drawToolContainer.appendChild(this.#currentTool)
        const ul = document.createElement('ul')
        ul.appendChild(this.GetToolboxIcon(PencilIcon, DrawType.FreeDraw))
        ul.appendChild(this.GetToolboxIcon(LineIcon, DrawType.Line))
        ul.appendChild(this.GetToolboxIcon(ArrowIcon, DrawType.Arrow))
        ul.appendChild(this.GetToolboxIcon(ElipseIcon, DrawType.Elipse))
        ul.appendChild(this.GetToolboxIcon(RectangleIcon, DrawType.Rectangle))
        ul.appendChild(this.GetToolboxIcon(BlockIcon, DrawType.Disable))
        this.#drawToolContainer.appendChild(ul)
        return this.#drawToolContainer
    }

    OnSelectTool (type: DrawType) { }
    ChangeColor = (color: string) => {
        this.#colorButtonIcon.style.color = color
    };

    ChangeBrushSize = (size: number) => {
        this.#brushSize = size
    };
}
