import { PopupContainer, ISize } from '../Utils/PopupContainer'
import { DrawType, IPosition } from './Anotate'

export interface ITextboxConfig {
  BoardContainer: HTMLDivElement;
  //   Width: number;
  //   Height: number;
  //   FontSize: number;
}
export interface ITextBox {
  UserId: string;
  Type: DrawType;
  Pos: IPosition;
  Text: string;
  Shape: ISize;
}
export class Textbox {
  private popup: PopupContainer;
  private boardContainer: HTMLDivElement;
  private width: number = 150;
  private height: number = 150;
  private fontSize: number = 24;
  private textarea: HTMLTextAreaElement = document.createElement('textarea');
  constructor (config: ITextboxConfig) {
      // this.width = config.Width;
      // this.height = config.Height;
      // this.fontSize = config.FontSize;
      this.boardContainer = config.BoardContainer
      this.popup = new PopupContainer({
          BoardContainer: this.boardContainer,
          Content: this.textarea,
          Width: this.width,
          Height: this.height,
          EnableResize: false
      })
      this.popup.OnSubmit = this.OnSubmit
      this.popup.OnCancel = this.OnCancel
      this.popup.Display(this.boardContainer, {
          X: 30,
          Y: 30
      })
  }

  OnTextboxSubmit (textbox: ITextBox) {}
  OnSubmit = () => {
      const text = this.textarea.value
      const pos = this.popup.GetPosition()
      const size = this.popup.GetSize()
      this.OnTextboxSubmit({
          Text: text,
          Pos: pos,
          Shape: size,
          Type: DrawType.Textbox,
          UserId: ''
      })
      this.Remove()
  };

  OnCancel = () => {
      this.popup.Remove()
  };

  OnClear = () => {
      this.popup.Remove()
  };

  DrawTextInCanvas = (text: string, position: IPosition, size: ISize) => {};

  Remove () {
      this.popup.Remove()
  }
}
