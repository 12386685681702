import { record } from 'rrweb'
import { listenerHandler } from 'rrweb/typings/types'
import { IConnectionMessage, IConnectionMessageType } from '../connection/ConnectionManager'

export interface ICobrowseSenderConfig {
    SessionId: string;
    UserId: string;
    OnSendMessage: (message: IConnectionMessage) => void
    OnResize: (width:number, height:number) => void
}

export class CobrowseSender {
    #sessionId:string;
    #userId: string;
    #recorder:listenerHandler|undefined;
    #onSendMessage:Function
    #onResize:Function
    constructor (config: ICobrowseSenderConfig) {
        this.#sessionId = config.SessionId
        this.#userId = config.UserId
        this.#onSendMessage = config.OnSendMessage
        this.#onResize = config.OnResize
        // this.init()
    }

    Init () {
        this.startRecording()
        // const width = document.body.clientWidth
        // const height = document.body.clientHeight
        const width = window.innerWidth
        const height = window.innerHeight
        this.#onResize(width, height)
        this.#onSendMessage({
            Type: IConnectionMessageType.RESIZE,
            Data: {
                w: width,
                h: height
            },
            SessionId: this.#sessionId,
            UserId: this.#userId
        })
    }

    startRecording = () => {
        const that = this
        // stop if recording started already
        this.#recorder?.()

        // start recording
        this.#recorder = record({
            emit: (event:any) => {
                // handle rrweb recorder events
                console.log(event)

                if (event.type === 2) {
                    // receiving dom data from recorder
                    const messageChunks = that.splitMessageToChunks(JSON.stringify(event), 2000)
                    messageChunks.forEach((chunk, index) => {
                        that.#onSendMessage({
                            Type: IConnectionMessageType.COBROWSE,
                            Data: {
                                chunkData: chunk,
                                hasMoreChunks: index !== messageChunks.length - 1
                            },
                            SessionId: that.#sessionId,
                            UserId: that.#userId
                        })
                    })
                } else {
                    // if event has scroll data
                    // if (event?.data?.source === 3) {
                    //     return
                    // }
                    // if (event.type === 4) {
                    //     event.data.width = document.body.clientWidth
                    //     event.data.height = document.body.clientHeight
                    // }
                    that.#onSendMessage({
                        Type: IConnectionMessageType.COBROWSEDATA,
                        Data: event,
                        SessionId: that.#sessionId,
                        UserId: that.#userId
                    })
                }
            },
            collectFonts: true,
            // recordLog: true,
            sampling: {
                // do not record mouse movement
                mousemove: true,
                // do not record mouse interaction
                mouseInteraction: {
                    MouseUp: false,
                    MouseDown: false,
                    Click: false,
                    ContextMenu: false,
                    DblClick: false,
                    Focus: false,
                    Blur: false,
                    TouchStart: false,
                    TouchEnd: false
                },
                // set the interval of scrolling event
                scroll: 150, // do not emit twice in 150ms
                // set the timing of record input
                input: 'last' // When input mulitple characters, only record the final input
            }
            // packFn: rrweb.pack,
        })
    }

    // https://stackoverflow.com/a/29202760/5860825
    splitMessageToChunks = (str:string, size:number) => {
        const numChunks = Math.ceil(str.length / size)
        const chunks = new Array(numChunks)

        for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
            chunks[i] = str.substr(o, size)
        }

        return chunks
    }
}
