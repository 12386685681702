import {
    DrawType,
    IDrawStyle,
    IDrawTool,
    DrawShape,
    IPosition
} from './Anotate'

export interface IElipse {
  UserId: string;
  Style: IDrawStyle;
  X1: number;
  Y1: number;
  X2: number;
  Y2: number;
  Type: DrawType;
}

export class Elipse implements IDrawTool {
  tempObj: IElipse = {
      UserId: '',
      Style: {
          brushColor: '#f8920f',
          brushSize: 5
      },
      Type: DrawType.Elipse,
      X1: -1,
      Y1: -1,
      X2: -1,
      Y2: -1
  };

  x1: number = 0;
  y1: number = 0;
  mousex: number = 0;
  mousey: number = 0;
  lastMouseX: number = 0;
  lastMouseY: number = 0;
  canvas: HTMLCanvasElement;
  context: CanvasRenderingContext2D;
  constructor (canvas: HTMLCanvasElement, style: IDrawStyle) {
      this.canvas = canvas
      this.tempObj.Style = style
      this.context = <CanvasRenderingContext2D> this.canvas.getContext('2d')
  }

  StartDrawing (style: IDrawStyle, pos: IPosition) {
      try {
          this.context.closePath()
          this.context.beginPath()
          this.tempObj.X1 = pos.X
          this.tempObj.Y1 = pos.Y
          this.tempObj.Style = { ...style }
      } catch (ex) {
          console.log('arrow.onMouseDown', ex)
      }
  }

  FinishDrawing (pos: IPosition) {
      try {
      // this.tempObj.X2 = pos.X
      // this.tempObj.Y2 = pos.Y
      // this.draw(this.tempObj);
      // this.save();
          if (this.tempObj.X1 !== -1 && this.tempObj.Y1 !== -1) {
              this.Draw(this.tempObj)
          }
      } catch (ex) {
          console.log('arrow.onMouseUp', ex)
      }
  }

  UpdatePositions (pos: IPosition) {
      try {
          this.context.clearRect(0, 0, this.canvas.width, this.canvas.height)
          this.mousex = pos.X - this.canvas.offsetLeft
          this.mousey = pos.Y - this.canvas.offsetLeft

          // this.tempObj.X1 = this.lastMouseX
          // this.tempObj.Y1 = this.lastMouseY
          this.tempObj.X2 = this.mousex
          this.tempObj.Y2 = this.mousey
          this.Draw(this.tempObj)
      } catch (ex) {
          console.log('arrow.onMouseMove', ex)
      }
  }

  Draw (obj: IElipse) {
      try {
      // ctx.clearRect(0, 0, canvas.width, canvas.height); //clear canvas
      // Save
          if (obj.X1 === -1 || obj.X2 === -1 || obj.Y1 === -1 || obj.Y2 === -1) {
              return
          }
          this.context.beginPath()
          this.context.save()
          // Dynamic scaling
          const scalex = 1 * ((obj.X2 - obj.X1) / 2)
          const scaley = 1 * ((obj.Y2 - obj.Y1) / 2)
          this.context.scale(scalex, scaley)
          // Create ellipse
          const centerx = obj.X1 / scalex + 1
          const centery = obj.Y1 / scaley + 1
          this.context.arc(centerx, centery, 1, 0, 2 * Math.PI)
          // Restore and draw
          this.context.restore()
          this.context.strokeStyle = obj.Style.brushColor
          this.context.lineWidth = obj.Style.brushSize
          this.context.closePath()
          this.context.stroke()
      } catch (ex) {
          console.log('elipse.onMouseDown', ex)
      }
  }

  GetShape (): DrawShape {
      return { ...this.tempObj }
  }

  GetStartPosition (): IPosition {
      return {
          X: this.tempObj.X1,
          Y: this.tempObj.Y1
      }
  }

  GetEndPosition (): IPosition {
      return {
          X: this.tempObj.X2,
          Y: this.tempObj.Y2
      }
  }
}
