type LogType = "Error" | "Debug" | "Info";
export class Logger {
  private ClassName: string;
  constructor(ClassName: string) {
    this.ClassName = ClassName;
  }

  Error(message: string) {
      console.error(this.GetFormatedText(message, 'Error'));
  }

  Debug(message: string) {
    console.log(this.GetFormatedText(message, 'Debug'));
  }

  Info(message: string) {
    console.log(this.GetFormatedText(message, 'Info'));
  }

  GetFormatedText(message: string, type: LogType): string {
    let dt = new Date();
    let time = dt.toLocaleTimeString("it-IT");
    let date = dt.toLocaleDateString();
    return date + " " + time + " " + type + " " + message;
  }
}
